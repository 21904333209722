var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, HostBinding } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
var SeeOurVideoComponent = /** @class */ (function () {
    function SeeOurVideoComponent(sanitizer) {
        this.sanitizer = sanitizer;
        this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle("url(" + window.images.landingPage.video + ")");
    }
    __decorate([
        HostBinding("style.backgroundImage"),
        __metadata("design:type", Object)
    ], SeeOurVideoComponent.prototype, "backgroundImage", void 0);
    SeeOurVideoComponent = __decorate([
        Component({
            selector: "app-see-our-video",
            template: require('./see-our-video.component.html'),
            styles: [require('./see-our-video.component.scss')],
        }),
        __metadata("design:paramtypes", [DomSanitizer])
    ], SeeOurVideoComponent);
    return SeeOurVideoComponent;
}());
export { SeeOurVideoComponent };
