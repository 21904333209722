var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { map } from "rxjs/operators/map";
var createAccountMutation = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation createAccount($input: RegistrationCreateAccountInput!) {\n    createAccount(input: $input) {\n      account {\n        id\n      }\n      signInUrl\n    }\n  }\n"], ["\n  mutation createAccount($input: RegistrationCreateAccountInput!) {\n    createAccount(input: $input) {\n      account {\n        id\n      }\n      signInUrl\n    }\n  }\n"])));
var RegistrationService = /** @class */ (function () {
    function RegistrationService(apollo) {
        this.apollo = apollo;
    }
    RegistrationService.prototype.createAccount = function (input) {
        return this.apollo
            .mutate({
            mutation: createAccountMutation,
            variables: { input: input },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.createAccount;
        }));
    };
    RegistrationService = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [Apollo])
    ], RegistrationService);
    return RegistrationService;
}());
export { RegistrationService };
var templateObject_1;
