var SmoothScroll = /** @class */ (function () {
    function SmoothScroll(element, options) {
        this.smoothScroll = function (element, options) {
            options = options || {};
            // Options
            var duration = options.duration || 800;
            var offset = options.offset || 0;
            var easing = options.easing || "easeInOutQuart";
            var callbackBefore = options.callbackBefore || (function () { });
            var callbackAfter = options.callbackAfter || (function () { });
            var container = document.getElementById(options.containerId) || undefined;
            var containerPresent = container !== null && container !== undefined;
            var middleAlign = options.middleAlign || false;
            /**
             * Retrieve current location
             */
            var getScrollLocation = function () {
                if (containerPresent) {
                    return container.scrollTop;
                }
                if (window.pageYOffset) {
                    return window.pageYOffset;
                }
                return document.documentElement.scrollTop;
            };
            /**
             * Calculate easing pattern.
             *
             * 20150713 edit - zephinzer
             * - changed if-else to switch
             * @see http://archive.oreilly.com/pub/a/server-administration/excerpts/even-faster-websites/writing-efficient-javascript.html
             */
            var getEasingPattern = function (type, time) {
                switch (type) {
                    case "easeInQuad":
                        return time * time; // accelerating from zero velocity
                    case "easeOutQuad":
                        return time * (2 - time); // decelerating to zero velocity
                    case "easeInOutQuad":
                        return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time; // acceleration until halfway, then deceleration
                    case "easeInCubic":
                        return time * time * time; // accelerating from zero velocity
                    case "easeOutCubic":
                        return (time -= 1) * time * time + 1; // decelerating to zero velocity
                    case "easeInOutCubic":
                        return time < 0.5
                            ? 4 * time * time * time
                            : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1; // acceleration until halfway, then deceleration
                    case "easeInQuart":
                        return time * time * time * time; // accelerating from zero velocity
                    case "easeOutQuart":
                        return 1 - (time -= 1) * time * time * time; // decelerating to zero velocity
                    case "easeInOutQuart":
                        return time < 0.5
                            ? 8 * time * time * time * time
                            : 1 - 8 * (time -= 1) * time * time * time; // acceleration until halfway, then deceleration
                    case "easeInQuint":
                        return time * time * time * time * time; // accelerating from zero velocity
                    case "easeOutQuint":
                        return 1 + (time -= 1) * time * time * time * time; // decelerating to zero velocity
                    case "easeInOutQuint":
                        return time < 0.5
                            ? 16 * time * time * time * time * time
                            : 1 + 16 * (time -= 1) * time * time * time * time; // acceleration until halfway, then deceleration
                    default:
                        return time;
                }
            };
            /**
             * Calculate how far to scroll
             */
            var getEndLocation = function (element) {
                var location = 0;
                var elementRect = element.getBoundingClientRect();
                var absoluteElementTop = elementRect.top + window.pageYOffset;
                if (middleAlign) {
                    location =
                        absoluteElementTop +
                            element.offsetHeight / 2 -
                            window.innerHeight / 2;
                }
                else {
                    location = absoluteElementTop;
                }
                if (offset) {
                    location = location - offset;
                }
                return Math.max(location, 0);
            };
            // Initialize the whole thing
            setTimeout(function () {
                var currentLocation;
                var startLocation = getScrollLocation();
                var endLocation = getEndLocation(element);
                var timeLapsed = 0;
                var distance = endLocation - startLocation;
                var percentage;
                var position;
                var scrollHeight;
                var internalHeight;
                /**
                 * Stop the scrolling animation when the anchor is reached (or at the top/bottom of the page)
                 */
                var stopAnimation = function () {
                    currentLocation = getScrollLocation();
                    if (containerPresent) {
                        scrollHeight = container.scrollHeight;
                        internalHeight = container.clientHeight + currentLocation;
                    }
                    else {
                        scrollHeight = document.body.scrollHeight;
                        internalHeight = window.innerHeight + currentLocation;
                    }
                    if (
                    // condition 1
                    position === endLocation || // condition 2
                        currentLocation === endLocation || // condition 3
                        internalHeight > scrollHeight) {
                        // stop
                        clearInterval(runAnimation);
                        callbackAfter(element);
                    }
                };
                /**
                 * Scroll the page by an increment, and check if it's time to stop
                 */
                var animateScroll = function () {
                    timeLapsed += 16;
                    percentage = timeLapsed / duration;
                    percentage = percentage > 1 ? 1 : percentage;
                    position =
                        startLocation + distance * getEasingPattern(easing, percentage);
                    if (containerPresent) {
                        container.scrollTop = position;
                    }
                    else {
                        window.scrollTo(0, position);
                    }
                    stopAnimation();
                };
                callbackBefore(element);
                var runAnimation = setInterval(animateScroll, 16);
            }, 0);
        };
        this.smoothScroll(element, options);
    }
    return SmoothScroll;
}());
export { SmoothScroll };
