var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PortalModule } from "@angular/cdk/portal";
import { DomSanitizer } from "@angular/platform-browser";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from "@angular/material/badge";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
var materialModules = [
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    PortalModule,
    MatBadgeModule,
];
var MaterialModule = /** @class */ (function () {
    function MaterialModule(iconRegistry, sanitizer) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var images = window.images;
        iconRegistry.addSvgIcon("white-logo-horizontal", sanitizer.bypassSecurityTrustResourceUrl(images.icons.whiteLogoHorizontal));
        iconRegistry.addSvgIcon("color-logo", sanitizer.bypassSecurityTrustResourceUrl(images.icons.colorLogo));
        iconRegistry.addSvgIcon("download", sanitizer.bypassSecurityTrustResourceUrl(images.icons.download));
        iconRegistry.addSvgIcon("exclamation-triangle", sanitizer.bypassSecurityTrustResourceUrl(images.icons.exclamationTriangle));
        iconRegistry.addSvgIcon("exclamation-circle", sanitizer.bypassSecurityTrustResourceUrl(images.icons.exclamationCircle));
        iconRegistry.addSvgIcon("check-circle", sanitizer.bypassSecurityTrustResourceUrl(images.icons.checkCircle));
        iconRegistry.addSvgIcon("times-circle", sanitizer.bypassSecurityTrustResourceUrl(images.icons.timesCircle));
        iconRegistry.addSvgIcon("paper-plane", sanitizer.bypassSecurityTrustResourceUrl(images.icons.paperPlane));
        iconRegistry.addSvgIcon("star", sanitizer.bypassSecurityTrustResourceUrl(images.icons.star));
        iconRegistry.addSvgIcon("bookmark", sanitizer.bypassSecurityTrustResourceUrl(images.icons.bookmark));
        iconRegistry.addSvgIcon("edit", sanitizer.bypassSecurityTrustResourceUrl(images.icons.edit));
        iconRegistry.addSvgIcon("file-certificate", sanitizer.bypassSecurityTrustResourceUrl(images.icons.fileCertificate));
        iconRegistry.addSvgIcon("users", sanitizer.bypassSecurityTrustResourceUrl(images.icons.users));
    }
    MaterialModule = __decorate([
        NgModule({
            exports: materialModules,
            imports: materialModules,
            providers: [{ provide: MAT_DATE_LOCALE, useValue: "es-ES" }],
        }),
        __metadata("design:paramtypes", [MatIconRegistry, DomSanitizer])
    ], MaterialModule);
    return MaterialModule;
}());
export { MaterialModule };
