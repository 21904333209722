var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ContactIdTypes } from "../../../../tenant/app/contacts/contacts.service";
import { RegistrationService } from "./service";
/**
 * @title Stepper overview
 */
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formBuilder, service, router, snackBar) {
        this.formBuilder = formBuilder;
        this.service = service;
        this.router = router;
        this.snackBar = snackBar;
        this.submitting = false;
        this.phoneNumberMask = [
            "+",
            /\d/,
            /[\d ]/,
            /[\d ]/,
            " ",
            /[1-9]/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ];
    }
    RegisterComponent.prototype.ngOnInit = function () {
        this.email = localStorage.getItem("confirmedEmail");
        this.emailToken = localStorage.getItem("confirmationId");
        if (!this.emailToken || !this.emailToken) {
            this.router.navigate(["/"], { replaceUrl: true });
        }
        this.userInfoDataForm = this.formBuilder.group({
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            identifier: this.formBuilder.group({
                identifierType: ["", Validators.required],
            }),
        });
        this.accountInfoForm = this.formBuilder.group({
            name: [""],
            email: [""],
            phone: [
                "",
                [
                    Validators.required,
                    Validators.minLength(13),
                    Validators.pattern("^\\+[0-9]{1,3} +[0-9]{8}$"),
                ],
            ],
            identifier: this.formBuilder.group({
                identifier: ["", Validators.required],
            }),
            address: this.addressForm = this.formBuilder.group({
                country: ["CR", [Validators.required]],
                state: [undefined],
                city: [undefined],
                streetLine1: ["", [Validators.required]],
                streetLine2: [""],
                provinceId: ["", [Validators.required]],
                cantonId: ["", [Validators.required]],
                districtId: ["", [Validators.required]],
            }),
        });
    };
    RegisterComponent.prototype.handleIdentifierChange = function () {
        var _this = this;
        var identifierControl = this.accountInfoForm.controls
            .identifier;
        if (this.isCompany) {
            this.accountInfoForm.controls.name.setValidators([Validators.required]);
            this.accountInfoForm.controls.email.setValidators([Validators.required]);
            identifierControl.controls.identifier.setValidators([
                Validators.required,
                Validators.pattern("^[0-9]-[0-9]{3}-[0-9]{6}$"),
            ]);
        }
        else {
            this.accountInfoForm.controls.name.setValidators([]);
            this.accountInfoForm.controls.email.setValidators([]);
            this.accountInfoForm.patchValue({ email: this.email });
            identifierControl.controls.identifier.setValidators([
                Validators.required,
                Validators.pattern("^[0-9]-[0-9]{4}-[0-9]{4}$"),
            ]);
        }
        this.accountInfoForm.patchValue({
            identifier: { identifier: "" },
            name: "",
        });
        this.accountInfoForm.controls.name.updateValueAndValidity();
        this.accountInfoForm.controls.email.updateValueAndValidity();
        var idType = ContactIdTypes.find(function (type) {
            return type.value === _this.userInfoDataForm.value.identifier.identifierType;
        });
        if (!idType) {
            return;
        }
        this.idMask = idType.mask;
    };
    RegisterComponent.prototype.submit = function () {
        var _this = this;
        this.submitting = true;
        var accountName = this.isCompany
            ? this.accountInfoForm.value.name
            : this.userInfoDataForm.value.firstName + " " + this.userInfoDataForm.value.lastName;
        this.service
            .createAccount({
            emailToken: this.emailToken,
            user: {
                firstName: this.userInfoDataForm.value.firstName,
                lastName: this.userInfoDataForm.value.lastName,
            },
            account: __assign(__assign({}, this.accountInfoForm.value), { identifier: {
                    identifierType: this.userInfoDataForm.value.identifier
                        .identifierType,
                    identifier: this.accountInfoForm.value.identifier.identifier.replace(/\D/g, ""),
                }, name: accountName }),
        })
            .subscribe(function (_a) {
            var signInUrl = _a.signInUrl;
            _this.submitting = false;
            localStorage.removeItem("confirmationId");
            localStorage.removeItem("confirmedEmail");
            localStorage.removeItem("confirmationEmail");
            document.location.href = signInUrl;
        }, function (_a) {
            var errors = _a.graphQLErrors;
            _this.submitting = false;
            if (errors.length > 0) {
                var errorCode = errors[0].extensions && errors[0].extensions.code;
                if (errorCode === "RECORD_INVALID") {
                    _this.error = errors[0].message;
                }
                else if (errorCode === "INVALID_TOKEN") {
                    localStorage.removeItem("confirmationId");
                    localStorage.removeItem("confirmedEmail");
                    localStorage.removeItem("confirmationEmail");
                    _this.router.navigate(["/"], { replaceUrl: true });
                    _this.snackBar.open("La cuenta no se ha podido crear, por favor inténtalo de nuevo");
                }
            }
        });
    };
    Object.defineProperty(RegisterComponent.prototype, "isCompany", {
        get: function () {
            return this.userInfoDataForm.value.identifier.identifierType === 2;
        },
        enumerable: false,
        configurable: true
    });
    RegisterComponent = __decorate([
        Component({
            selector: "home-register-component",
            template: require('./register.component.html'),
            styles: [require('./register.component.scss')],
        }),
        __metadata("design:paramtypes", [FormBuilder,
            RegistrationService,
            Router,
            MatSnackBar])
    ], RegisterComponent);
    return RegisterComponent;
}());
export { RegisterComponent };
