var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./not-found.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
var appRoutes = [
    {
        children: [
            {
                component: LandingPageComponent,
                path: "",
            },
        ],
        path: "",
    },
    {
        path: "registration",
        loadChildren: function() { return import('/Users/guille/Code/numeros/numeros/app/javascript/home/app/registration/registration.module')  .then( function(module) { return module['RegistrationModule']; } ); },
        data: { preload: true },
    },
    { path: "**", component: PageNotFoundComponent },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    AppRoutingModule = __decorate([
        NgModule({
            exports: [RouterModule],
            imports: [
                RouterModule.forRoot(appRoutes, {
                    enableTracing: false,
                }),
            ],
        })
    ], AppRoutingModule);
    return AppRoutingModule;
}());
export { AppRoutingModule };
