var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { APOLLO_OPTIONS, ApolloModule } from "apollo-angular";
import { HttpLink, HttpLinkModule } from "apollo-angular-link-http";
import { defaultDataIdFromObject, InMemoryCache } from "apollo-cache-inmemory";
var uri = "/graphql";
var GraphQLModule = /** @class */ (function () {
    function GraphQLModule() {
    }
    GraphQLModule = __decorate([
        NgModule({
            imports: [HttpClientModule, ApolloModule, HttpLinkModule],
            providers: [
                {
                    provide: APOLLO_OPTIONS,
                    useFactory: function (httpLink) {
                        return {
                            cache: new InMemoryCache({
                                dataIdFromObject: function (object) {
                                    switch (object.__typename) {
                                        case "FacturaElectronicaInvoice":
                                            return object.__typename + ":" + object.key; // use `key` as the primary key
                                        default:
                                            return defaultDataIdFromObject(object); // fall back to default handling
                                    }
                                },
                            }),
                            link: httpLink.create({
                                uri: uri,
                            }),
                        };
                    },
                    deps: [HttpLink],
                },
            ],
        })
    ], GraphQLModule);
    return GraphQLModule;
}());
export { GraphQLModule };
