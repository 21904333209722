var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GetStartedService } from "../service.service";
var GetStartedFormComponent = /** @class */ (function () {
    function GetStartedFormComponent(formBuilder, router, service) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.router = router;
        service.pricingPlanSelected$.subscribe(function () {
            _this.emailInput.nativeElement.focus();
        });
    }
    GetStartedFormComponent.prototype.ngOnInit = function () {
        this.emailForm = this.formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
        });
    };
    GetStartedFormComponent.prototype.submit = function () {
        localStorage.setItem("confirmationEmail", this.emailForm.value.email);
        localStorage.removeItem("confirmationId");
        this.router.navigate(["/registration/confirm-email"]);
    };
    __decorate([
        ViewChild("emailInput", { static: false }),
        __metadata("design:type", ElementRef)
    ], GetStartedFormComponent.prototype, "emailInput", void 0);
    GetStartedFormComponent = __decorate([
        Component({
            selector: "app-get-started-form",
            template: require('./get-started-form.component.html'),
            styles: [require('./get-started-form.component.scss')],
        }),
        __metadata("design:paramtypes", [FormBuilder,
            Router,
            GetStartedService])
    ], GetStartedFormComponent);
    return GetStartedFormComponent;
}());
export { GetStartedFormComponent };
