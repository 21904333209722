var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators/map";
import { ActionsObserverService, CONTACT_MODEL, } from "@tenant/services/actions-observer.service";
import contactsQuery from "./contacts.graphql";
import getContactQuery from "./getContact.graphql";
import createContactQuery from "./createContact.graphql";
import updateContactQuery from "./updateContact.graphql";
import deleteContactsQuery from "./deleteContacts.graphql";
import multipleContactsUpdateQuery from "./multipleContactsUpdate.graphql";
export var ContactIdTypes = [
    {
        value: 1,
        viewValue: "Cedula Fisica",
        mask: [/[1-9]/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
    },
    {
        value: 2,
        viewValue: "Cedula Jurídica",
        mask: [
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ],
    },
    {
        value: 3,
        viewValue: "DIMEX",
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    },
    {
        value: 4,
        viewValue: "NITE",
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    },
    {
        value: 99,
        viewValue: "Otro",
        mask: false,
    },
];
var ContactsService = /** @class */ (function () {
    function ContactsService(apollo, actionObserver) {
        this.apollo = apollo;
        this.actionObserver = actionObserver;
    }
    ContactsService.prototype.getContacts = function (filter) {
        if (filter === void 0) { filter = {}; }
        return this.apollo.watchQuery({
            query: contactsQuery,
            variables: { filter: filter },
        });
    };
    ContactsService.prototype.getContact = function (id) {
        return this.apollo.watchQuery({
            query: getContactQuery,
            variables: { id: id },
        });
    };
    ContactsService.prototype.createContact = function (contact) {
        var _this = this;
        return this.apollo
            .mutate({
            mutation: createContactQuery,
            variables: { contact: contact },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            _this.actionObserver.action.next({
                model: CONTACT_MODEL,
                action: "created",
                data: data.createContact.contact,
            });
            return data.createContact;
        }));
    };
    ContactsService.prototype.updateContact = function (_a) {
        var _this = this;
        var id = _a.id, __typename = _a.__typename, contact = __rest(_a, ["id", "__typename"]);
        return this.apollo
            .mutate({
            mutation: updateContactQuery,
            variables: { id: id, contact: contact },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            _this.actionObserver.action.next({
                model: CONTACT_MODEL,
                action: "updated",
                data: { id: id },
            });
            return data.updateContact.updatedId;
        }));
    };
    ContactsService.prototype.delete = function (contacts) {
        var _this = this;
        var ids = contacts.map(function (c) { return c.id; });
        return this.apollo
            .mutate({
            mutation: deleteContactsQuery,
            variables: { ids: ids },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            _this.actionObserver.action.next({
                model: CONTACT_MODEL,
                action: "deleted",
                data: { ids: ids },
            });
            return data.deleteContacts.deletedIds;
        }));
    };
    ContactsService.prototype.archive = function (contacts) {
        var _this = this;
        var ids = contacts.map(function (c) { return c.id; });
        return this.apollo
            .mutate({
            mutation: multipleContactsUpdateQuery,
            variables: { ids: ids, attributes: { archived: true } },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            _this.actionObserver.action.next({
                model: CONTACT_MODEL,
                action: "archived",
                data: { ids: ids },
            });
            return data.updateContacts.contacts.map(function (contact) { return contact.id; });
        }));
    };
    ContactsService.prototype.activate = function (contacts) {
        var _this = this;
        var ids = contacts.map(function (c) { return c.id; });
        return this.apollo
            .mutate({
            mutation: multipleContactsUpdateQuery,
            variables: { ids: ids, attributes: { archived: false } },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            _this.actionObserver.action.next({
                model: CONTACT_MODEL,
                action: "activated",
                data: { ids: ids },
            });
            return data.updateContacts.contacts.map(function (contact) { return contact.id; });
        }));
    };
    ContactsService = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [Apollo,
            ActionsObserverService])
    ], ContactsService);
    return ContactsService;
}());
export { ContactsService };
