var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent() {
        this.state = "transparent";
        this.showNavbar = false;
        this.state = window.innerWidth < 768 ? "white" : "transparent";
    }
    NavbarComponent.prototype.checkScroll = function () {
        var componentHeight = this.navBarRef.nativeElement.clientHeight;
        var scrollPosition = window.pageYOffset;
        if (window.innerWidth < 768) {
            this.state = "white";
            return;
        }
        this.state =
            scrollPosition >= componentHeight
                ? "white"
                : (this.state = "transparent");
    };
    __decorate([
        ViewChild("navBar", { static: false }),
        __metadata("design:type", ElementRef)
    ], NavbarComponent.prototype, "navBarRef", void 0);
    __decorate([
        HostListener("window:resize", ["$event"]),
        HostListener("window:scroll", ["$event"]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], NavbarComponent.prototype, "checkScroll", null);
    NavbarComponent = __decorate([
        Component({
            selector: "app-navbar",
            template: require('./navbar.component.html'),
            styles: [require('./navbar.component.scss')],
        }),
        __metadata("design:paramtypes", [])
    ], NavbarComponent);
    return NavbarComponent;
}());
export { NavbarComponent };
