var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { TextMaskModule } from "angular2-text-mask";
import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MaterialModule } from "@shared/material.module";
import { GraphQLModule } from "@shared/graphql.module";
import { AddressService } from "@tenant/address/address.service";
import { AddressFormComponent } from "@tenant/address/address-form.component";
import { RegistrationRoutingModule } from "./registration-routing.module";
import { EmailConfirmationService } from "./confirm-email/service";
import { RegistrationService } from "./register/service";
import { RegisterComponent } from "./register/register.component";
import { ConfirmEmailComponent } from "./confirm-email/confirm-email.component";
import { CodeInputComponent } from "./confirm-email/code-input/code-input.component";
import { RegistrationComponent } from "./registration.component";
import { RegistrationTopBarComponent } from "./top-bar/registration-top-bar.component";
var RegistrationModule = /** @class */ (function () {
    function RegistrationModule() {
    }
    RegistrationModule = __decorate([
        NgModule({
            declarations: [
                RegistrationComponent,
                RegisterComponent,
                ConfirmEmailComponent,
                CodeInputComponent,
                AddressFormComponent,
                RegistrationTopBarComponent,
            ],
            imports: [
                CommonModule,
                ReactiveFormsModule,
                MatStepperModule,
                TranslateModule,
                GraphQLModule,
                TextMaskModule,
                MaterialModule,
                FontAwesomeModule,
                RegistrationRoutingModule,
            ],
            exports: [],
            providers: [EmailConfirmationService, RegistrationService, AddressService],
        })
    ], RegistrationModule);
    return RegistrationModule;
}());
export { RegistrationModule };
