var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, Input } from "@angular/core";
import { GetStartedService } from "../get-started/service.service";
var PricingBoxComponent = /** @class */ (function () {
    function PricingBoxComponent(service) {
        this.service = service;
        this.commingSoon = false;
        this.free = false;
    }
    PricingBoxComponent.prototype.selectPlan = function () {
        this.service.selectPricingPlan(this.plan);
    };
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], PricingBoxComponent.prototype, "plan", void 0);
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], PricingBoxComponent.prototype, "title", void 0);
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], PricingBoxComponent.prototype, "price", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], PricingBoxComponent.prototype, "commingSoon", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], PricingBoxComponent.prototype, "free", void 0);
    PricingBoxComponent = __decorate([
        Component({
            selector: "home-pricing-box",
            template: require('./pricing-box.component.html'),
            styles: [require('./pricing-box.component.scss')],
        }),
        __metadata("design:paramtypes", [GetStartedService])
    ], PricingBoxComponent);
    return PricingBoxComponent;
}());
export { PricingBoxComponent };
