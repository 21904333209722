var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Directive, ElementRef, HostListener, Input } from "@angular/core";
var ParallaxDirective = /** @class */ (function () {
    function ParallaxDirective(eleRef) {
        this.eleRef = eleRef;
        this.ratio = 1;
        this.initialTop = 0;
        this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
    }
    ParallaxDirective.prototype.onWindowScroll = function (_event) {
        this.eleRef.nativeElement.style.top = this.initialTop -
            window.scrollY * this.ratio + "px";
    };
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], ParallaxDirective.prototype, "ratio", void 0);
    __decorate([
        HostListener("window:scroll", ["$event"]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ParallaxDirective.prototype, "onWindowScroll", null);
    ParallaxDirective = __decorate([
        Directive({
            selector: "[appParallax]",
        }),
        __metadata("design:paramtypes", [ElementRef])
    ], ParallaxDirective);
    return ParallaxDirective;
}());
export { ParallaxDirective };
