var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { MatSnackBar } from "@angular/material/snack-bar";
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ContactMessageService } from "../contact-message.service";
var ContactUsFormComponent = /** @class */ (function () {
    function ContactUsFormComponent(service, snackBar, translate) {
        this.service = service;
        this.snackBar = snackBar;
        this.translate = translate;
        this.submitting = false;
        this.submitted = false;
        this.contactForm = new FormGroup({
            email: new FormControl("", [Validators.required, Validators.email]),
            message: new FormControl("", Validators.required),
            name: new FormControl("", Validators.required),
            subject: new FormControl("", Validators.required),
        });
    }
    ContactUsFormComponent.prototype.submitMessage = function () {
        var _this = this;
        if (this.submitting) {
            return;
        }
        this.submitting = true;
        this.service.submitMessage(this.contactForm.value).subscribe(function () {
            _this.translate
                .get(["contact_form.message.sent", "buttons.ok"])
                .subscribe(function (texts) {
                _this.snackBar.open(texts["contact_form.message.sent"], texts["buttons.ok"], {
                    duration: 5000,
                });
            });
            _this.submitting = false;
            _this.submitted = true;
            _this.contactForm.reset();
        }, function () {
            _this.translate
                .get(["contact_form.message.error", "buttons.ok"])
                .subscribe(function (texts) {
                _this.snackBar.open(texts["contact_form.message.error"], texts["buttons.ok"]);
            });
            _this.submitting = false;
        });
    };
    ContactUsFormComponent = __decorate([
        Component({
            selector: "app-contact-us-form",
            styles: [require('./contact-us-form.component.scss')],
            template: require('./contact-us-form.component.html'),
        }),
        __metadata("design:paramtypes", [ContactMessageService,
            MatSnackBar,
            TranslateService])
    ], ContactUsFormComponent);
    return ContactUsFormComponent;
}());
export { ContactUsFormComponent };
