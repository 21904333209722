var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from "@angular/core";
var GetStartedComponent = /** @class */ (function () {
    function GetStartedComponent() {
        this.image = "url(" + window.images.landingPage.gettingStarted + ")";
        this.registerEnabled = true;
    }
    GetStartedComponent = __decorate([
        Component({
            selector: "home-get-started",
            template: require('./get-started.component.html'),
            styles: [require('./get-started.component.scss')],
        })
    ], GetStartedComponent);
    return GetStartedComponent;
}());
export { GetStartedComponent };
