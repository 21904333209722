import { of as observableOf } from "rxjs/observable/of";
import es from "../i18n/es.json";
var translations = {
    es: es,
};
var AssetTranslateLoader = /** @class */ (function () {
    function AssetTranslateLoader() {
    }
    AssetTranslateLoader.prototype.getTranslation = function (lang) {
        return observableOf(translations[lang]);
    };
    return AssetTranslateLoader;
}());
export { AssetTranslateLoader };
