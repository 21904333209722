var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RegistrationComponent } from "./registration.component";
import { RegisterComponent } from "./register/register.component";
import { ConfirmEmailComponent } from "./confirm-email/confirm-email.component";
var registrationRoutes = [
    {
        path: "",
        component: RegistrationComponent,
        children: [
            {
                path: "confirm-email",
                component: ConfirmEmailComponent,
            },
            {
                path: "complete",
                component: RegisterComponent,
            },
        ],
    },
];
var RegistrationRoutingModule = /** @class */ (function () {
    function RegistrationRoutingModule() {
    }
    RegistrationRoutingModule = __decorate([
        NgModule({
            imports: [RouterModule.forChild(registrationRoutes)],
            exports: [RouterModule],
        })
    ], RegistrationRoutingModule);
    return RegistrationRoutingModule;
}());
export { RegistrationRoutingModule };
