var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
export var CONTACT_MODEL = "Contact";
export var PRODUCT_MODEL = "Product";
export var USER_MODEL = "User";
export var EXPENSE_CATEGORY_MODEL = "ExpenseCategory";
var ActionsObserverService = /** @class */ (function () {
    function ActionsObserverService() {
        this.action = new Subject();
        this.action$ = this.action.asObservable();
    }
    ActionsObserverService = __decorate([
        Injectable()
    ], ActionsObserverService);
    return ActionsObserverService;
}());
export { ActionsObserverService };
