var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { map } from "rxjs/operators/map";
var sendConfirmationEmailMutation = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation sendConfirmationEmail($email: String!) {\n    sendConfirmationEmail(input: { email: $email }) {\n      id\n      email\n    }\n  }\n"], ["\n  mutation sendConfirmationEmail($email: String!) {\n    sendConfirmationEmail(input: { email: $email }) {\n      id\n      email\n    }\n  }\n"])));
var validateConfirmationCodeMutation = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation validateConfirmationCode($code: Int!, $id: String!) {\n    validateConfirmationCode(input: { code: $code, id: $id }) {\n      errorCode\n      valid\n    }\n  }\n"], ["\n  mutation validateConfirmationCode($code: Int!, $id: String!) {\n    validateConfirmationCode(input: { code: $code, id: $id }) {\n      errorCode\n      valid\n    }\n  }\n"])));
var EmailConfirmationService = /** @class */ (function () {
    function EmailConfirmationService(apollo) {
        this.apollo = apollo;
    }
    EmailConfirmationService.prototype.sendConfirmationEmail = function (email) {
        return this.apollo
            .mutate({
            mutation: sendConfirmationEmailMutation,
            variables: { email: email },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.sendConfirmationEmail;
        }));
    };
    EmailConfirmationService.prototype.validateConfirmationCode = function (code, id) {
        return this.apollo
            .mutate({
            mutation: validateConfirmationCodeMutation,
            variables: { code: code, id: id },
        })
            .pipe(map(function (_a) {
            var data = _a.data;
            return data.validateConfirmationCode;
        }));
    };
    EmailConfirmationService = __decorate([
        Injectable(),
        __metadata("design:paramtypes", [Apollo])
    ], EmailConfirmationService);
    return EmailConfirmationService;
}());
export { EmailConfirmationService };
var templateObject_1, templateObject_2;
