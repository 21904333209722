// tslint:disable-next-line:no-import-side-effect
import "@shared/polyfills.ts";
// tslint:disable-next-line:ordered-imports no-import-side-effect
import "../stylesheets/home.scss";
// tslint:disable-next-line:ordered-imports
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
    enableProdMode();
}
document.addEventListener("DOMContentLoaded", function () {
    platformBrowserDynamic().bootstrapModule(AppModule);
});
