var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, forwardRef, QueryList, ViewChildren, } from "@angular/core";
export var CODE_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    // tslint:disable-next-line:no-forward-ref no-use-before-declare
    useExisting: forwardRef(function () { return CodeInputComponent; }),
    multi: true,
};
var CodeInputComponent = /** @class */ (function () {
    function CodeInputComponent() {
        this.disabled = false;
    }
    Object.defineProperty(CodeInputComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
            this.notifyValueChange();
        },
        enumerable: false,
        configurable: true
    });
    CodeInputComponent.prototype.notifyValueChange = function () {
        if (this.onChange) {
            this.onChange(this.value);
        }
    };
    CodeInputComponent.prototype.ngOnInit = function () { };
    CodeInputComponent.prototype.writeValue = function (obj) {
        this._value = obj;
        if (!this.inputs) {
            return;
        }
        this.inputs.forEach(function (input, index) {
            input.nativeElement.value = (obj && obj[index]) || "";
        });
        this.inputs.first.nativeElement.focus();
    };
    CodeInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CodeInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    CodeInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    CodeInputComponent.prototype.handlePaste = function (event) {
        event.preventDefault();
        var value = event.clipboardData
            .getData("text/plain")
            .replace(/[^0-9]/g, "");
        if (value) {
            if (value.length === this.inputs.length) {
                this.inputs.forEach(function (input, index) {
                    input.nativeElement.value = value[index] || "";
                });
            }
            else {
                var index_1;
                this.inputs.forEach(function (input) {
                    if (input.nativeElement === event.target) {
                        index_1 = 0;
                    }
                    if (typeof index_1 !== undefined) {
                        input.nativeElement.value =
                            value[index_1] || input.nativeElement.value;
                        index_1++;
                    }
                });
            }
            this.value = value;
        }
    };
    CodeInputComponent.prototype.handleKeyDown = function (event, index) {
        var _this = this;
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode === 8) {
            // backspace
            if (!event.target.value && index > 0) {
                var previousInput = this.inputAt(index - 1);
                previousInput.nativeElement.focus();
            }
            return true;
        }
        if (charCode >= 48 && charCode <= 57) {
            if (event.target.value) {
                event.target.value = "";
            }
            setTimeout(function () {
                if (index < 5 && event.target.value) {
                    var nextInput = _this.inputAt(index + 1);
                    nextInput.nativeElement.focus();
                }
                var value = _this.inputs
                    .map(function (input) { return input.nativeElement.value; })
                    .join("");
                if (value !== _this.value) {
                    _this.value = value;
                }
            });
        }
    };
    CodeInputComponent.prototype.handleKeyPress = function (event) {
        // tslint:disable-next-line:deprecation
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        var value = this.inputs
            .map(function (input) { return input.nativeElement.value; })
            .join("");
        if (value !== this.value) {
            this.value = value;
        }
        return true;
    };
    CodeInputComponent.prototype.inputAt = function (index) {
        return this.inputs.find(function (_item, itemIndex) { return itemIndex === index; });
    };
    __decorate([
        ViewChildren("input"),
        __metadata("design:type", QueryList)
    ], CodeInputComponent.prototype, "inputs", void 0);
    CodeInputComponent = __decorate([
        Component({
            selector: "home-code-input",
            template: require('./code-input.component.html'),
            styles: [require('./code-input.component.scss')],
            providers: [CODE_INPUT_VALUE_ACCESSOR],
        })
    ], CodeInputComponent);
    return CodeInputComponent;
}());
export { CodeInputComponent };
