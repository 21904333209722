var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Directive, HostListener, Input } from "@angular/core";
import { SmoothScroll } from "./smooth-scroll";
var SmoothScrollToDirective = /** @class */ (function () {
    function SmoothScrollToDirective() {
    }
    SmoothScrollToDirective.prototype.onClick = function (event) {
        event.preventDefault();
        this.targetElement = document.getElementById(this.scrollTo);
        if (!this.targetElement) {
            return;
        }
        this.smoothScroll = new SmoothScroll(this.targetElement, {
            duration: this.duration,
            offset: this.offset,
            easing: this.easing,
            callbackBefore: this.callbackBefore,
            callbackAfter: this.callbackAfter,
            containerId: this.containerId,
            middleAlign: this.middleAlign,
        });
    };
    __decorate([
        Input("appScrollTo"),
        __metadata("design:type", String)
    ], SmoothScrollToDirective.prototype, "scrollTo", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Number)
    ], SmoothScrollToDirective.prototype, "duration", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Number)
    ], SmoothScrollToDirective.prototype, "offset", void 0);
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], SmoothScrollToDirective.prototype, "easing", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], SmoothScrollToDirective.prototype, "callbackBefore", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], SmoothScrollToDirective.prototype, "callbackAfter", void 0);
    __decorate([
        Input(),
        __metadata("design:type", String)
    ], SmoothScrollToDirective.prototype, "containerId", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], SmoothScrollToDirective.prototype, "middleAlign", void 0);
    __decorate([
        HostListener("click", ["$event"]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], SmoothScrollToDirective.prototype, "onClick", null);
    SmoothScrollToDirective = __decorate([
        Directive({
            selector: "[appScrollTo]",
        })
    ], SmoothScrollToDirective);
    return SmoothScrollToDirective;
}());
export { SmoothScrollToDirective };
