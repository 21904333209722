var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AddressService } from "./address.service";
var AddressFormComponent = /** @class */ (function () {
    function AddressFormComponent(service) {
        this.service = service;
        this.save = new EventEmitter();
        this.vertical = false;
        this.enableCountrySelection = true;
        this.defaultCountry = "";
        this.provinces = [];
        this.countries = [];
        this.cantons = [];
        this.districts = [];
    }
    AddressFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.service
            .loadFormData()
            .valueChanges.subscribe(function (_a) {
            var _b = _a.data, countries = _b.countries, provinces = _b.provinces;
            _this.countries = countries;
            _this.provinces = provinces;
            if (_this.defaultCountry && !_this.formGroup.value.country) {
                _this.formGroup.patchValue({ country: _this.defaultCountry });
            }
            _this.updateCantons();
            _this.updateDistricts();
        });
    };
    AddressFormComponent.prototype.updateCantons = function (provinceId) {
        if (provinceId === void 0) { provinceId = this.formGroup.value.provinceId; }
        if (!provinceId || this.provinces.length === 0) {
            this.cantons = [];
            this.formGroup.controls.cantonId.disable();
            return;
        }
        this.cantons = this.provinces.find(function (p) { return p.id === provinceId; }).cantons;
        this.formGroup.controls.cantonId.enable();
    };
    AddressFormComponent.prototype.updateDistricts = function (cantonId) {
        if (cantonId === void 0) { cantonId = this.formGroup.value.cantonId; }
        if (!cantonId || this.cantons.length === 0) {
            this.districts = [];
            this.formGroup.controls.districtId.disable();
            return;
        }
        this.districts = this.cantons.find(function (p) { return p.id === cantonId; }).districts;
        this.formGroup.controls.districtId.enable();
    };
    AddressFormComponent.prototype.provinceChanged = function (provinceId) {
        this.formGroup.patchValue({
            cantonId: undefined,
            districtId: undefined,
        });
        this.updateCantons(provinceId);
        this.updateDistricts();
    };
    AddressFormComponent.prototype.cantonChanged = function (cantonId) {
        this.formGroup.value.districtId = undefined;
        this.updateDistricts(cantonId);
    };
    Object.defineProperty(AddressFormComponent.prototype, "layout", {
        get: function () {
            return this.vertical ? "column" : "row";
        },
        enumerable: false,
        configurable: true
    });
    AddressFormComponent.prototype.trackById = function (_index, object) {
        return object.id;
    };
    __decorate([
        Input(),
        __metadata("design:type", FormGroup)
    ], AddressFormComponent.prototype, "formGroup", void 0);
    __decorate([
        Output(),
        __metadata("design:type", Object)
    ], AddressFormComponent.prototype, "save", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], AddressFormComponent.prototype, "vertical", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], AddressFormComponent.prototype, "enableCountrySelection", void 0);
    __decorate([
        Input(),
        __metadata("design:type", Object)
    ], AddressFormComponent.prototype, "defaultCountry", void 0);
    AddressFormComponent = __decorate([
        Component({
            selector: "app-address-form",
            template: require('./address-form.component.html'),
            styles: [require('./address-form.component.scss')],
        }),
        __metadata("design:paramtypes", [AddressService])
    ], AddressFormComponent);
    return AddressFormComponent;
}());
export { AddressFormComponent };
