var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { EmailConfirmationService } from "./service";
var ConfirmEmailComponent = /** @class */ (function () {
    function ConfirmEmailComponent(formBuilder, router, service) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.router = router;
        this.service = service;
        this.confirmationSent = false;
        this.showInfoMessage = true;
        this.confirmationEmail = localStorage.getItem("confirmationEmail");
        this.confirmationId = localStorage.getItem("confirmationId");
        this.confirmedEmail = localStorage.getItem("confirmedEmail");
        if (!this.confirmationEmail) {
            this.router.navigate(["/"], { replaceUrl: true });
        }
        if (this.confirmedEmail && this.confirmedEmail !== this.confirmationEmail) {
            localStorage.removeItem("confirmedEmail");
            this.confirmedEmail = undefined;
        }
        else if (this.confirmedEmail &&
            this.confirmedEmail === this.confirmationEmail) {
            this.router.navigate(["registration", "complete"]);
        }
        if (!this.confirmationId) {
            this.service
                .sendConfirmationEmail(this.confirmationEmail)
                .subscribe(function (_a) {
                var id = _a.id, email = _a.email;
                if (email === _this.confirmationEmail) {
                    _this.confirmationSent = true;
                    _this.confirmationId = id;
                    localStorage.setItem("confirmationId", id);
                }
            });
        }
        else {
            this.confirmationSent = true;
        }
    }
    ConfirmEmailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.emailConfirmationForm = this.formBuilder.group({
            code: ["", [Validators.required, Validators.minLength(6)]],
        });
        this.emailConfirmationForm.valueChanges.subscribe(function (_a) {
            var code = _a.code;
            if (code.length === 6) {
                _this.service
                    .validateConfirmationCode(parseInt(code, 10), _this.confirmationId)
                    .subscribe(function (_a) {
                    var valid = _a.valid;
                    if (valid) {
                        _this.error = undefined;
                        localStorage.setItem("confirmedEmail", _this.confirmationEmail);
                        _this.router.navigate(["registration", "complete"], {
                            replaceUrl: true,
                        });
                    }
                    else {
                        _this.emailConfirmationForm.patchValue({ code: "" });
                        _this.error = "Ese código no era válido. ¡Intentalo de nuevo!";
                    }
                });
            }
            _this.showInfoMessage = false;
            _this.error = undefined;
        });
    };
    ConfirmEmailComponent.prototype.submit = function () {
        this.router.navigate(["/registration/complete"], {
            queryParams: { email: this.emailConfirmationForm.value.email },
        });
    };
    ConfirmEmailComponent = __decorate([
        Component({
            selector: "home-confirm-email",
            template: require('./confirm-email.component.html'),
            styles: [require('./confirm-email.component.scss')],
        }),
        __metadata("design:paramtypes", [FormBuilder,
            Router,
            EmailConfirmationService])
    ], ConfirmEmailComponent);
    return ConfirmEmailComponent;
}());
export { ConfirmEmailComponent };
