var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from "@angular/core";
import { faBookmark } from "@fortawesome/free-solid-svg-icons/faBookmark";
var AboutUsComponent = /** @class */ (function () {
    function AboutUsComponent() {
        this.faBookmark = faBookmark;
    }
    AboutUsComponent = __decorate([
        Component({
            selector: "app-about-us",
            styles: [require('./about-us.component.scss')],
            template: require('./about-us.component.html'),
        })
    ], AboutUsComponent);
    return AboutUsComponent;
}());
export { AboutUsComponent };
