var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { InViewportModule } from "@thisissoon/angular-inviewport";
import { ScrollSpyModule } from "@thisissoon/angular-scrollspy";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es-CR";
import { MaterialModule } from "@shared/material.module";
import { FooterSocialComponent } from "./landing-page/footer-social/footer-social.component";
import { RegistrationModule } from "./registration/registration.module";
import { AssetTranslateLoader } from "./shared/webpack-translate-loader";
import { IndexComponent } from "./index.component";
import { PageNotFoundComponent } from "./not-found.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { ParallaxDirective } from "./landing-page/parallax.directive";
import { AppRoutingModule } from "./app-routing.module";
import { SmoothScrollToDirective } from "./navbar/scroll-to.directive";
import { ContactUsFormComponent } from "./landing-page/contact-us-form/contact-us-form.component";
import { IntroComponent } from "./landing-page/intro/intro.component";
import { AboutUsComponent } from "./landing-page/about-us/about-us.component";
import { ContactMessageService } from "./landing-page/contact-message.service";
import { GetStartedComponent } from "./landing-page/get-started/get-started.component";
import { PricingBoxComponent } from "./landing-page/pricing-box/pricing-box.component";
import { SeeOurVideoComponent } from "./landing-page/see-our-video/see-our-video.component";
import { PricingComponent } from "./landing-page/pricing/pricing.component";
import { GetStartedFormComponent } from "./landing-page/get-started/get-started-form/get-started-form.component";
import { DeviceFriendlyComponent } from "./landing-page/device-friendly/device-friendly.component";
import { GetStartedService } from "./landing-page/get-started/service.service";
import { ContactInfoComponent } from "./landing-page/contact-info/contact-info.component";
registerLocaleData(localeEs);
// required for AOT compilation
export var HttpLoaderFactory = function () {
    return new AssetTranslateLoader();
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    AppModule = __decorate([
        NgModule({
            bootstrap: [IndexComponent],
            declarations: [
                NavbarComponent,
                IndexComponent,
                PageNotFoundComponent,
                LandingPageComponent,
                FooterSocialComponent,
                ContactUsFormComponent,
                IntroComponent,
                AboutUsComponent,
                GetStartedComponent,
                GetStartedFormComponent,
                ContactInfoComponent,
                PricingComponent,
                PricingBoxComponent,
                SeeOurVideoComponent,
                ParallaxDirective,
                SmoothScrollToDirective,
                DeviceFriendlyComponent,
            ],
            imports: [
                BrowserModule,
                AppRoutingModule,
                MaterialModule,
                HttpClientModule,
                TranslateModule.forRoot({
                    loader: {
                        deps: [HttpClient],
                        provide: TranslateLoader,
                        useFactory: HttpLoaderFactory,
                    },
                }),
                ReactiveFormsModule,
                FontAwesomeModule,
                BrowserAnimationsModule,
                InViewportModule,
                ScrollSpyModule.forRoot(),
                RegistrationModule,
            ],
            providers: [ContactMessageService, GetStartedService],
        })
    ], AppModule);
    return AppModule;
}());
export { AppModule };
